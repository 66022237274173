export class MembersContactList {
  static readonly type = '[MembersContactList] Post';
  constructor(public param: {}) {}
}

export class SaveManageMember {
  static readonly type = '[SaveManageMember] Post';
  constructor(public param: FormData) {}
}

export class DeleteMembersContact {
  static readonly type = '[DeleteMembersContact] Post';
  constructor(public param: { id: number }) {}
}

export class DeleteMemberFile {
  static readonly type = '[DeleteMemberFile] Post';
  constructor(public param: IDeleteMemberFile) {}
}
