import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http';
import {
  DeleteMemberFile,
  DeleteMembersContact,
  MembersContactList,
  SaveManageMember,
} from '@app/store/actions/website-action/manage-member.action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';

interface IManageMemberStateModel {
  membersContactList: IMembersContactList[];
  membersContactListCount: number;
  membersContactId: number;
}
@State<IManageMemberStateModel>({
  name: 'ManageMemberState',
  defaults: {
    membersContactList: [],
    membersContactListCount: 0,
    membersContactId: 0,
  },
})
@Injectable()
export class ManageMemberState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}

  @Selector()
  static membersContactList(state: IManageMemberStateModel) {
    return state.membersContactList;
  }

  @Selector()
  static membersContactListCount(state: IManageMemberStateModel) {
    return state.membersContactListCount;
  }

  @Action(MembersContactList)
  MembersContactList(
    ctx: StateContext<IManageMemberStateModel>,
    { param }: MembersContactList
  ) {
    return this._http.post('members/contact-list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          membersContactList: resultData.contacts,
          membersContactListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(SaveManageMember)
  SaveManageMember(
    ctx: StateContext<IManageMemberStateModel>,
    { param }: SaveManageMember
  ) {
    return this._http.post('members/add-edit-contact', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          membersContactId: resultData.id,
        });
        this._toastr.success(apiResult.response.status.msg, 'success', {});
      })
    );
  }

  @Action(DeleteMembersContact)
  DeleteMembersContact(
    ctx: StateContext<IManageMemberStateModel>,
    { param }: DeleteMembersContact
  ) {
    return this._http.post('members/contact-delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            membersContactList: removeItem<IMembersContactList>(
              (item) => item.id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(DeleteMemberFile)
  DeleteDripFile(
    ctx: StateContext<IManageMemberStateModel>,
    { param }: DeleteMemberFile
  ) {
    return this._http.post('file/delete', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });

        ctx.setState(
          patch<IManageMemberStateModel>({
            membersContactList: updateItem<IMembersContactList>(
              (item) => item.id === param.id,
              patch({ image: '' })
            ),
          })
        );
      })
    );
  }
}
